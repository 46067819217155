<template>
  <v-row v-if="hasAdminRights" class="switch-admin-view ma-0 justify-center">

    <p
      v-if="licenseExpired"
      class="error--text font-weight-bold text-uppercase mx-4 mb-0 text-h5"
    >
      {{ $t('license-expired') }}
    </p>
    <v-col cols="12" class="d-flex justify-end align-center">
      <p
        v-if="isTherapy"
        class="text-caption mr-1 text-uppercase font-weight-bold"
      >
        {{ $t('adminTable.availableTests', { amount: validTests }) }}
      </p>
      <p class="text-caption text-uppercase font-weight-bold">
        {{ $t('adminTable.licenseUntil', { date: licenseUntilDate }) }}
      </p>
    </v-col>

    <v-col cols="12" sm="6" md="5" lg="4" xl="3">
      <v-btn block class="" color="primary" @click="switchView">
        <v-icon v-if="isStudentTableRoute" class="mr-2" large
          >mdi-arrow-left-bold-box</v-icon
        >
        <p v-if="isTherapy" class="ma-0 text-h6">
          {{
            isTeacherTableRoute
              ? $t('adminTable.to-patient-table')
              : $t('adminTable.to-admin-table')
          }}
        </p>
        <p v-else class="ma-0 text-h6">
          {{
            isTeacherTableRoute
              ? $t('adminTable.to-students-table')
              : $t('adminTable.to-admin-table')
          }}
        </p>
        <v-icon v-if="isTeacherTableRoute" class="ml-2" large
          >mdi-arrow-right-bold-box</v-icon
        >
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import dayjs from 'dayjs';

@Component
export default class SwitchViewForAdmin extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  get isTeacherTableRoute(): boolean {
    return this.$route.name === 'teachers';
  }

  get isStudentTableRoute(): boolean {
    return this.$route.name === 'students';
  }

  get isTherapy(): boolean {
    return this.schoolModule.isTherapyClient;
  }

  get hasAdminRights(): boolean {
    const userId = this.userModule.user?._id;
    const adminIds = this.schoolModule.adminIds;
    return adminIds?.includes(userId ?? '');
  }
  get licenseUntilDate(): string {
    return this.schoolModule.school?.licenseUntil !== ''
      ? dayjs(this.schoolModule.school.licenseUntil).format('DD.MM.YYYY')
      : '';
  }

  get validTests(): number {
    return this.schoolModule.school?.validTests ?? 0;
  }

  get licenseExpired(): boolean {
    return this.schoolModule.licenseExpired;
  }

  switchView() {
    if (this.isTeacherTableRoute) {
      this.$router.push({
        name: 'students',
        params: { schoolName: getSchoolPrefix() },
      });
    } else {
      this.$router.push({
        name: 'teachers',
        params: { schoolName: getSchoolPrefix() },
      });
    }
  }
}
</script>

<style scoped lang="scss">
.switch-admin-view {
  width: 100%;
}
</style>
